export class FormUIHandler {
  static applyStyles() {
    const styles = `
			.error {
				border-color: #e53e3e !important;
			}

			.tooltip {
				position: absolute;
				z-index: 10;
				top: calc(100% + 0.5rem);
				left: 0;
				display: none;
				width: 100%;
				padding: 0.5rem;
				font-size: 0.875rem;
				color: #fff;
				background-color: #e53e3e;
				border-radius: 0.25rem;
				text-align: center;
			}

			.error ~ .tooltip {
				display: block;
			}

			.tooltip::after {
				content: "";
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				border-width: 0.5rem;
				border-style: solid;
				border-color: transparent transparent #e53e3e transparent;
			}

			.disabled {
				background-color: #ddd;
				opacity: 0.5;
				cursor: not-allowed !important;
			}
		`;

    const styleElement = document.createElement("style");
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
  }

  static showErrorTooltip(field, message) {
    field.classList.add("error");

    const tooltip = document.createElement("div");
    tooltip.classList.add("tooltip");
    tooltip.textContent = message;
    field.parentNode.appendChild(tooltip);
  }

  static hideErrorTooltip(field) {
    field.classList.remove("error");

    const tooltip = field.parentNode.querySelector(".tooltip");
    if (tooltip) {
      tooltip.remove();
    }
  }

  static applyFieldError(field, errorMessage) {
    this.removeSuccessStyles(field); // Remove success styles if error encountered
    this.showErrorTooltip(field, errorMessage);
  }

  static applySuccessStyles(field) {
    field.style.borderColor = "#48bb78";
    const successIcon = document.createElement("div");
    successIcon.classList.add("success-icon");
    successIcon.style.position = "absolute";
    successIcon.style.right = "10px";
    successIcon.style.top = "50%";
    successIcon.style.transform = "translateY(-50%)";

    successIcon.innerHTML = `<svg width="20px" height="20px" fill="#48bb78" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
			<path d="m27.988 51.672c0.375 0 0.9843-0.1406 1.5938-0.4688 13.312-7.4765 17.906-10.641 17.906-19.195v-17.93c0-2.4609-1.0782-3.2343-3.0469-4.0781-2.7656-1.1484-11.719-4.3594-14.484-5.3203-0.6328-0.211-1.289-0.3516-1.9687-0.3516-0.6562 0-1.3125 0.1875-1.9453 0.3516-2.7656 0.8437-11.719 4.1953-14.484 5.3203-1.9687 0.8203-3.0469 1.6172-3.0469 4.0781v17.93c0 8.5547 4.6172 11.695 17.906 19.195 0.6094 0.3282 1.1953 0.4688 1.5703 0.4688zm0-4.2656c-0.3516 0-0.75-0.1407-1.4531-0.5625-10.828-6.5625-14.297-8.4844-14.297-15.703v-16.359c0-0.7969 0.164-1.1016 0.7969-1.3594 3.5625-1.4063 10.43-3.8438 14.039-5.1328 0.3516-0.1407 0.6563-0.211 0.9141-0.211 0.2812 0 0.5625 0.0703 0.9375 0.211 3.6094 1.289 10.406 3.8906 14.039 5.1328 0.6329 0.2343 0.7969 0.5625 0.7969 1.3594v16.359c0 7.2188-3.4922 9.1172-14.297 15.703-0.7031 0.4218-1.1015 0.5625-1.4765 0.5625zm-2.9297-8.3203c0.7969 0 1.4297-0.3751 1.8984-1.1251l11.578-18.211c0.3047-0.4453 0.5859-0.9844 0.5859-1.5 0-1.0781-0.9609-1.7578-1.9453-1.7578-0.6094 0-1.1953 0.375-1.6172 1.0781l-10.594 16.945-5.4374-7.0547c-0.5391-0.6796-1.0782-0.9375-1.7344-0.9375-1.0313 0-1.8282 0.8204-1.8282 1.875 0 0.5157 0.211 1.0313 0.5391 1.4766l6.5391 8.1094c0.6562 0.75 1.2422 1.1016 2.0156 1.1016z"/>
			</svg>`;

    if (!field.parentNode.querySelector(".success-icon")) {
      field.parentNode.appendChild(successIcon);
    }
  }

  static removeSuccessStyles(field) {
    field.style.borderColor = "";

    const successIcon = field.parentNode.querySelector(".success-icon");
    if (successIcon) {
      successIcon.remove();
    }
  }

  static applyFieldSuccess(field) {
    this.hideErrorTooltip(field);
    this.applySuccessStyles(field);
  }

  static applyDisabledButtonStyles(button) {
    button.disabled = true;
    button.classList.add("disabled");
  }

  static removeDisabledButtonStyles(button) {
    button.disabled = false;
    button.classList.remove("disabled");
  }

  static updateAddress(address) {
    const providedAddressElement = document.getElementById("providedAddress");
    providedAddressElement.textContent = address;
  }

  static updateSelectedCheckboxes(selectedValues) {
    const selectedCheckboxesContainer = document.getElementById(
      "selectedCheckboxesValues"
    );

    // Clear the container before adding new badges
    selectedCheckboxesContainer.innerHTML = "";

    if (selectedValues && Array.isArray(selectedValues)) {
      // Create a badge for each selected value
      selectedValues.forEach((value) => {
        const badge = document.createElement("span");
        badge.textContent = value;

        // Set the CSS style directly
        badge.style.display = "inline-flex";
        badge.style.alignItems = "center";
        badge.style.padding = "0.5rem 0.625rem";
        badge.style.borderRadius = "4px"; // Adjust the border-radius value
        badge.style.border = "1px dashed #A0AEC0"; // Change to dashed subtle gray border
        badge.style.backgroundColor = "#FFFFFF"; // Add white background
        badge.style.color = "#000000";
        badge.style.fontSize = "0.875rem";
        badge.style.marginRight = "0.5rem";
        badge.style.marginBottom = "0.5rem";
        badge.style.position = "relative";
        badge.style.boxShadow = "0 2px 4px 0 rgba(0, 0, 0, 0.1)"; // Add shadow

        // Append the badge to the container
        selectedCheckboxesContainer.appendChild(badge);
      });
    }
  }

  static displayModal(modalId, message, email, phone) {
    const styles = `
    .modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom right, rgba(59, 130, 246, 0.75), rgba(46, 204, 113, 0.75));
      z-index: 1000000000;
    }

    .modal-content {
      border-radius: 0.5rem;
      box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 1.5rem;
    }

    .modal-title {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: #1a202c;
    }

    .modal-description {
      font-size: 1.25rem;
      color: #374151;
      margin-bottom: 1rem;
    }

    .modal-info {
      color: #4a5568;
      margin-top: 1rem;
    }

    .modal-close {
      margin-top: 1.5rem;
      border-radius: 0.25rem;
      background-color: #1e40af;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }

    .modal-close:hover {
      background-color: #1c3d8d;
    }
  `;

    const modal = document.createElement("div");
    modal.id = modalId;
    modal.classList.add("modal");

    const modalContent = document.createElement("div");
    modalContent.classList.add("modal-content");

    const title = document.createElement("p");
    title.classList.add("modal-title");
    title.textContent = message;

    const description = document.createElement("p");
    description.classList.add("modal-description");
    description.textContent =
      "Please check your postal code or contact us if you think it's worthwhile to travel further.";

    const emailInfo = document.createElement("p");
    emailInfo.classList.add("modal-info");
    emailInfo.textContent = `Email: ${email}`;

    const phoneInfo = document.createElement("p");
    phoneInfo.classList.add("modal-info");
    phoneInfo.textContent = `Phone: ${phone}`;

    const closeButton = document.createElement("button");
    closeButton.classList.add("modal-close");
    closeButton.textContent = "Close";
    closeButton.addEventListener("click", () => {
      FormUIHandler.closeModal(modalId);
    });

    modalContent.appendChild(title);
    modalContent.appendChild(description);
    modalContent.appendChild(emailInfo);
    modalContent.appendChild(phoneInfo);
    modalContent.appendChild(closeButton);

    modal.appendChild(modalContent);

    const styleElement = document.createElement("style");
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    document.body.appendChild(modal);
  }
}

FormUIHandler.applyStyles();
