import axios from "axios";
import Bottleneck from "bottleneck";
import NodeCache from "node-cache";
import ReverseGeocode from "../ReverseGeocode/ReverseGeocode.class";

export default class OpenStreetMapService extends ReverseGeocode {
	constructor() {
		super();
		this.limiter = new Bottleneck({
			minTime: 1000, // Minimum time between requests
		});
		this.cache = new NodeCache({ stdTTL: 100, checkperiod: 120 });
	}

	async getFullAddress(postalCode, country = "nl") {
		const cacheKey = `fullAddress:${postalCode}:${country}`;

		const cachedAddress = this.cache.get(cacheKey);
		if (cachedAddress) {
			return cachedAddress;
		}

		try {
			const response = await this.limiter.schedule(() =>
				axios.get(
					`https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&country=${country}&format=jsonv2`
				)
			);

			const results = response.data;

			if (results.length > 0) {
				this.cache.set(cacheKey, results[0].display_name);
				return results[0].display_name;
			} else {
				throw new Error(
					"No results returned from OpenStreetMap Nominatim API."
				);
			}
		} catch (error) {
			this.handleError(error);
		}
	}

	async getCoordsFromPostalCode(postalCode, country = "nl") {
		const cacheKey = `coords:${postalCode}:${country}`;

		const cachedCoords = this.cache.get(cacheKey);
		if (cachedCoords) {
			return cachedCoords;
		}

		try {
			const response = await this.limiter.schedule(() =>
				axios.get(
					`https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&country=${country}&format=json`
				)
			);

			const results = response.data;

			if (results.length > 0) {
				const coords = {
					lat: results[0].lat,
					lon: results[0].lon,
				};
				this.cache.set(cacheKey, coords);
				return coords;
			} else {
				throw new Error(
					"No results returned from OpenStreetMap Nominatim API."
				);
			}
		} catch (error) {
			this.handleError(error);
		}
	}

	async getAddressFromCoords(lat, lon) {
		const cacheKey = `address:${lat}:${lon}`;

		const cachedAddress = this.cache.get(cacheKey);
		if (cachedAddress) {
			return cachedAddress;
		}

		try {
			const response = await this.limiter.schedule(() =>
				axios.get(
					`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=jsonv2`
				)
			);

			const result = response.data;

			if (result && result.address) {
				const address = result.address;
				this.cache.set(cacheKey, address);
				return address;
			} else {
				throw new Error("Address not found in the response.");
			}
		} catch (error) {
			this.handleError(error);
		}
	}

	handleError(error) {
		if (error.response) {
			switch (error.response.status) {
				case 400:
					console.error("Bad request to the OpenStreetMap API:", error.message);
					break;
				case 429:
					console.error(
						"Too many requests to the OpenStreetMap API:",
						error.message
					);
					break;
				case 500:
					console.error(
						"Server error from the OpenStreetMap API:",
						error.message
					);
					break;
				default:
					console.error(
						"Received unexpected status code:",
						error.response.status
					);
			}
		} else {
			console.error(
				"Error sending request to OpenStreetMap API:",
				error.message
			);
		}
	}
}
