import { FormUIHandler } from "../FormUIHandler/FormUIHandler.class";
import { FormValidator } from "../FormValidator/FormValidator.class";

export class FormHandler {
  constructor(formId, uiHandler = FormUIHandler) {
    this.form = document.getElementById(formId);
    this.fields = Array.from(this.form.querySelectorAll("input"));
    this.submitButton = this.form.querySelector("button[type='submit']");
    this.uiHandler = uiHandler;
    this.callback = null;
    this.init();
  }

  init() {
    // Clear the checked state of checkboxes on page load
    this.fields.forEach((field) => {
      if (field.type === "checkbox") {
        field.checked = false;
      }
    });

    this.fields.forEach((field) => {
      if (field.type === "checkbox") {
        field.addEventListener("change", () =>
          this.updateSelectedCheckboxesUI()
        );
      } else {
        field.addEventListener("input", () => this.validateAndDisplay(field));
        field.addEventListener("blur", () => this.validateAndDisplay(field));
      }
    });

    this.bindFormSubmitEvent();

    if (typeof this.callback === "function") {
      this.form.addEventListener("submit", this.callback);
    }
  }

  bindFormSubmitEvent() {
    this.form.addEventListener("submit", (event) => {
      event.preventDefault();
      if (this.fields.every((field) => this.validateAndDisplay(field))) {
        this.handleFormSubmit();
      } else {
        this.uiHandler.applyDisabledButtonStyles(this.submitButton);
      }
    });
  }

  updateAddressUI() {
    const postalCodeField = this.fields.find(
      (field) => field.id === "postalCodeInput"
    );
    const cityField = this.fields.find((field) => field.id === "cityInput");
    const streetField = this.fields.find((field) => field.id === "streetInput");
    const houseNumberField = this.fields.find(
      (field) => field.id === "houseNumberInput"
    );

    const fullAddress = `${streetField.value} ${houseNumberField.value}, ${postalCodeField.value} ${cityField.value}`;

    this.uiHandler.updateAddress(fullAddress);
  }

  updateSelectedCheckboxesUI() {
    const selectedCheckboxes = this.fields.filter(
      (field) => field.type === "checkbox" && field.checked
    );

    const selectedCheckboxesValues = selectedCheckboxes.map(
      (checkbox) => checkbox.value
    );

    this.uiHandler.updateSelectedCheckboxes(selectedCheckboxesValues);
  }

  checkFieldsAndSetButtonState() {
    if (this.fields.every((field) => field.value.trim() !== "")) {
      this.uiHandler.removeDisabledButtonStyles(this.submitButton);
    } else {
      this.uiHandler.applyDisabledButtonStyles(this.submitButton);
    }
  }

  validateAndDisplay(field) {
    if (field.type === "checkbox") {
      this.validateAndDisplayCheckboxGroup(field);
    } else {
      this.validateAndDisplayField(field);
      if (
        [
          "postalCodeInput",
          "cityInput",
          "streetInput",
          "houseNumberInput",
        ].includes(field.id)
      ) {
        this.updateAddressUI();
      }
    }
  }

  validateAndDisplayField(field) {
    const errorMessage = FormValidator.validateField(field);
    if (errorMessage) {
      this.uiHandler.applyFieldError(field, errorMessage);
      this.uiHandler.applyDisabledButtonStyles(this.submitButton);
      return false;
    } else {
      this.uiHandler.applyFieldSuccess(field);
      if (this.fields.every((f) => !FormValidator.validateField(f))) {
        this.uiHandler.removeDisabledButtonStyles(this.submitButton);
      }
      return true;
    }
  }

  validateAndDisplayCheckboxGroup(field) {
    const checkboxes = this.fields.filter((f) => f.type === "checkbox");
    const errorMessage = FormValidator.validateCheckboxGroup(checkboxes, 1, 7);
    if (errorMessage) {
      // Display error message
      alert(errorMessage); // or any other way you'd like to display the error
      return false;
    } else {
      if (this.fields.every((f) => !FormValidator.validateField(f))) {
        this.uiHandler.removeDisabledButtonStyles(this.submitButton);
      }
      return true;
    }
  }

  handleFormSubmit() {
    if (typeof this.callback === "function") {
      this.callback();
    }
  }

  setSubmitCallback(callback) {
    this.callback = callback;
    if (typeof callback === "function") {
      this.form.addEventListener("submit", callback);
    }
  }
}
