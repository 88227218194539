/**
 * Class representing an Event Bus.
 */
export class EventBus {
	/**
	 * The Singleton instance.
	 * @type {EventBus}
	 */
	static instance;

	/**
	 * Create an EventBus instance.
	 */
	constructor() {
		if (!EventBus.instance) {
			this.subscribers = new Map();
			this.eventMetadata = new Map();
			this.eventMetrics = new Map();
			EventBus.instance = this;
		}

		return EventBus.instance;
	}

	/**
	 * Subscribe to an event.
	 * @param {Object} eventAction - The event action object.
	 * @param {string} eventAction.eventType - The event type.
	 * @param {Function} eventAction.callback - The callback function.
	 * @param {number} [eventAction.priority=0] - The priority of the subscriber.
	 */
	subscribe(eventAction) {
		const { eventType, callback, priority = 0 } = eventAction;
		const subscribers = this.subscribers.get(eventType) || [];
		subscribers.push({ callback, priority });
		this.subscribers.set(eventType, subscribers);
	}

	/**
	 * Subscribe to multiple events.
	 * @param  {...Object} eventActions - The event action objects.
	 */
	subscribeMultiple(...eventActions) {
		eventActions.forEach((eventAction) => this.subscribe(eventAction));
	}

	/**
	 * Unsubscribe from an event.
	 * @param {string} eventType - The event type.
	 * @param {Function} callback - The callback function.
	 */
	unsubscribe(eventType, callback) {
		const subscribers = this.subscribers.get(eventType) || [];
		const index = subscribers.findIndex(
			(subscriber) => subscriber.callback === callback
		);
		if (index !== -1) {
			subscribers.splice(index, 1);
			this.subscribers.set(eventType, subscribers);
		}
	}

	/**
	 * Publish an event.
	 * @param {string} eventType - The event type.
	 * @param {*} data - The event data.
	 * @param {number} [priority] - The priority of the event.
	 */
	publish(eventType, data, priority) {
		const subscribers = this.subscribers.get(eventType) || [];
		const eventMetadata = this.eventMetadata.get(eventType) || {};

		const event = {
			eventType,
			data,
			metadata: eventMetadata,
		};

		if (typeof priority === "number") {
			subscribers.sort((a, b) => b.priority - a.priority);
		}

		subscribers.forEach((subscriber) => {
			try {
				subscriber.callback(event);
			} catch (error) {
				console.error(
					`Error occurred while handling event: ${eventType}`,
					error
				);
			}
		});

		this.incrementEventCount(eventType);
	}

	/**
	 * Set metadata for an event.
	 * @param {string} eventType - The event type.
	 * @param {*} metadata - The metadata to set.
	 */
	setMetadata(eventType, metadata) {
		this.eventMetadata.set(eventType, metadata);
	}

	/**
	 * Get metadata for an event.
	 * @param {string} eventType - The event type.
	 * @returns {*} The metadata for the event.
	 */
	getMetadata(eventType) {
		return this.eventMetadata.get(eventType);
	}

	/**
	 * Get events that occurred since a specific timestamp.
	 * @param {string} eventType - The event type.
	 * @param {number} timestamp - The timestamp to compare against.
	 * @returns {Array} The events that occurred since the timestamp.
	 */
	getEventsSince(eventType, timestamp) {
		const events = this.eventMetrics.get(eventType) || [];
		return events.filter((event) => event.timestamp >= timestamp);
	}

	/**
	 * Expire events that have exceeded a specified duration.
	 * @param {string} eventType - The event type.
	 * @param {number} duration - The duration in milliseconds.
	 */
	expireEvents(eventType, duration) {
		const events = this.eventMetrics.get(eventType) || [];
		const currentTime = Date.now();
		const expiredEvents = events.filter(
			(event) => currentTime - event.timestamp >= duration
		);
		expiredEvents.forEach((event) => {
			const index = events.indexOf(event);
			if (index !== -1) {
				events.splice(index, 1);
			}
		});
	}

	/**
	 * Monitor event metrics and invoke a callback function.
	 * @param {Function} metricsCallback - The callback function to invoke for each event metric.
	 */
	monitorMetrics(metricsCallback) {
		this.eventMetrics.forEach((events, eventType) => {
			const count = events.length;
			metricsCallback(eventType, count);
		});
	}

	/**
	 * Increment the event count for tracking metrics.
	 * @param {string} eventType - The event type.
	 */
	incrementEventCount(eventType) {
		const events = this.eventMetrics.get(eventType) || [];
		events.push({ timestamp: Date.now() });
		this.eventMetrics.set(eventType, events);
	}
}

export const GlobalEventBus = new EventBus();
