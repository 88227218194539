export default class ReverseGeocode {
	constructor(apiKey) {
		this.apiKey = apiKey || "";
	}

	async getFullAddress(postalCode, country = "nl") {
		throw new Error("getFullAddress() method is not implemented.");
	}

	async getCoordsFromPostalCode(postalCode, country = "nl") {
		throw new Error("getCoordsFromPostalCode() method is not implemented.");
	}

	async getAddressFromCoords(lat, lon) {
		throw new Error("getStreetFromCoords() method is not implemented.");
	}
}
