import { GlobalEventBus } from "../EventBus/EventBus.class.js";

export class LocationRetriever {
	constructor(geolocationService, reverseGeocodingService) {
		this.geolocationService = geolocationService;
		this.reverseGeocodingService = reverseGeocodingService;
	}

	async retrieveLocation(location) {
		try {
			// Validate the input location data
			this.validateLocationData(location);

			// Retrieve the location based on the input data
			let address;
			if (this.geolocationService) {
				// Retrieve the coordinates from geolocation
				const coords = await this.retrieveCoordinates();
				address = await this.reverseGeocodingService.getAddressFromCoords(
					coords.latitude,
					coords.longitude
				);
			} else if (typeof location === "string") {
				// Retrieve the coordinates from postal code
				const coords =
					await this.reverseGeocodingService.getCoordsFromPostalCode(location);
				address = await this.reverseGeocodingService.getAddressFromCoords(
					coords.lat,
					coords.lon
				);
			} else {
				// Retrieve the address from coordinates
				const { latitude, longitude } = location;
				address = await this.reverseGeocodingService.reverseGeocode(
					latitude,
					longitude
				);
			}

			// Dispatch the "location.retrieved" event
			GlobalEventBus.publish("location.retrieved", address);

			return address;
		} catch (error) {
			console.error("Error retrieving location:", error);
			throw new Error("Failed to retrieve location.");
		}
	}

	async retrieveCoordinates() {
		try {
			const position = await this.geolocationService.getCurrentPosition();
			const { latitude, longitude } = position.coords;
			return { latitude, longitude };
		} catch (error) {
			console.error("Error retrieving coordinates:", error);
			throw new Error("Failed to retrieve coordinates.");
		}
	}

	validateLocationData(location) {
		if (this.geolocationService) {
			// No validation required when geolocationService is available
			return;
		}

		if (typeof location === "string") {
			// Validate postal code format
			const postalCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
			if (!postalCodeRegex.test(location)) {
				throw new Error("Invalid postal code provided.");
			}
		} else if (
			typeof location !== "object" ||
			typeof location.latitude !== "number" ||
			typeof location.longitude !== "number"
		) {
			throw new Error("Invalid location data provided.");
		}
	}
}
