/**
 * Represents a class that enables smooth scrolling and focuses on an input element when triggered by a link.
 */
export class InputFocus {
	/**
	 * Creates an instance of the InputFocus class.
	 */
	constructor() {
		// Select all links with the 'data-input-focus' attribute
		this.links = document.querySelectorAll("a[data-input-focus]");
		// Bind the event handler method to the class instance
		this.focusInput = this.focusInput.bind(this);
		// Attach event listeners to the links
		this.attachEventListeners();
	}

	/**
	 * Event handler method that focuses on the input element and scrolls to it.
	 * @param {Event} event - The click event triggered by the link.
	 */
	focusInput(event) {
		event.preventDefault();
		// Get the target input element selector from the 'data-input-focus' attribute of the link
		const target = event.currentTarget.getAttribute("data-input-focus");
		// Find the input element based on the selector
		const input = document.querySelector(target);
		if (input) {
			// Scroll smoothly to the input element
			this.smoothScrollTo(input);
			// Observe the visibility of the input element
			this.observeInputVisibility(input);
		}
	}

	/**
	 * Scrolls smoothly to the specified element.
	 * @param {HTMLElement} element - The element to scroll to.
	 */
	smoothScrollTo(element) {
		window.scrollTo({
			behavior: "smooth",
			top: element.offsetTop,
		});
	}

	/**
	 * Observes the visibility of the input element using an Intersection Observer.
	 * When the input element becomes visible, it is focused on and the observer is disconnected.
	 * @param {HTMLElement} input - The input element to observe.
	 */
	observeInputVisibility(input) {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						// Focus on the input element when it becomes visible
						input.focus();
						// Disconnect the observer since the input element is now focused
						observer.disconnect();
					}
				});
			},
			{ threshold: 0.5 } // Set the visibility threshold to 50%
		);

		// Start observing the input element's visibility
		observer.observe(input);
	}

	/**
	 * Attaches event listeners to the links for triggering the focus on the input element.
	 */
	attachEventListeners() {
		this.links.forEach((link) => {
			link.addEventListener("click", this.focusInput);
		});
	}
}
