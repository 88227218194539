import { GlobalEventBus } from "../EventBus/EventBus.class.js";

/**
 * Class representing an Input Updater.
 */
export class InputUpdater {
	/**
	 * Create an InputUpdater instance.
	 * @param {HTMLElement[]} inputElements - The array of input elements to update.
	 */
	constructor(inputElements) {
		this.inputElements = inputElements;
	}

	/**
	 * Update the input elements with the provided input data.
	 * @param {Object} inputData - The object of input data.
	 * @throws {TypeError} If the input data is missing or invalid.
	 */
	updateInput(inputData) {
		if (typeof inputData !== "object" || inputData === null) {
			throw new TypeError("Invalid input data.");
		}

		Object.entries(inputData).forEach(([key, value]) => {
			const inputElement = this.findInputElement(key);
			if (inputElement) {
				inputElement.value = value;
			}
		});

		this.dispatchInputUpdatedEvent();
	}

	/**
	 * Finds the input element with the specified ID or name.
	 * @param {string} idOrName - The ID or name of the input element.
	 * @returns {HTMLElement|null} The input element, or null if not found.
	 */
	findInputElement(idOrName) {
		return this.inputElements.find(
			(inputElement) =>
				inputElement.id === idOrName || inputElement.name === idOrName
		);
	}

	/**
	 * Dispatches the input updated event.
	 */
	dispatchInputUpdatedEvent() {
		GlobalEventBus.publish("input.updated", this.inputElements);
	}
}
