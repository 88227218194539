/**
 * Represents a Masked Input field.
 */
export class MaskedInput {
	/**
	 * Creates an instance of MaskedInput.
	 * @param {HTMLInputElement} inputElement - The input element to apply the mask to.
	 * @param {string} [mask] - The mask pattern to apply. If not provided, it will be read from the `data-mask` attribute of the input element.
	 */
	constructor(inputElement, mask) {
		/**
		 * The input element.
		 * @type {HTMLInputElement}
		 */
		this.input = inputElement;

		/**
		 * The mask pattern for input validation.
		 * @type {string}
		 */
		this.mask = mask || this.input.getAttribute("data-mask");

		this.input.addEventListener("keyup", this.applyMask.bind(this));
	}

	/**
	 * Applies the mask to the input value.
	 */
	applyMask() {
		const value = this.input.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();
		let maskedValue = "";
		let valueIndex = 0;

		for (let i = 0; i < this.mask.length; i++) {
			const maskChar = this.mask[i];
			const valueChar = value[valueIndex];

			if (!valueChar) break;

			if (maskChar === "0") {
				if (/[0-9]/.test(valueChar)) {
					maskedValue += valueChar;
					valueIndex++;
				}
			} else if (maskChar === "A") {
				if (/[A-Za-z]/.test(valueChar) && valueIndex >= 4) {
					maskedValue += valueChar;
					valueIndex++;
				}
			} else {
				maskedValue += maskChar;
			}
		}

		this.input.value = maskedValue;
	}

	/**
	 * Sets a custom mask pattern.
	 * @param {string} mask - The custom mask pattern to apply.
	 */
	setMask(mask) {
		this.mask = mask;
		this.applyMask();
	}

	/**
	 * Clears the input value and removes the mask.
	 */
	clear() {
		this.input.value = "";
	}

	/**
	 * Resets the input value to the original value with the mask applied.
	 */
	reset() {
		this.applyMask();
	}
}
