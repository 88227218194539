export class FormValidator {
  static validateField(field) {
    const fieldValue = field.value.trim();
    const fieldName = field.name;

    if (fieldValue === "") {
      return field.dataset.tooltip || "This field is required.";
    }

    // Removed validation for single checkbox as it's now part of checkbox group validation

    if (
      fieldName === "postal_code" &&
      !FormValidator.isValidPostalCode(fieldValue)
    ) {
      return (
        field.dataset.tooltip ||
        "Please enter a valid postal code (e.g., 1782 AB)."
      );
    }

    if (field.type === "email" && !FormValidator.isValidEmail(fieldValue)) {
      return field.dataset.tooltip || "Please enter a valid email address.";
    }

    if (
      fieldName === "custom_field" &&
      !FormValidator.containsSubstring(fieldValue, "example")
    ) {
      return (
        field.dataset.tooltip || "The field must contain the word 'example'."
      );
    }

    if (
      field.type === "password" &&
      !FormValidator.checkPasswordStrength(fieldValue)
    ) {
      return field.dataset.tooltip || "Please enter a stronger password.";
    }

    const otherField = Array.from(field.form.querySelectorAll("input")).find(
      (f) => f.name === "dependent_field"
    );
    if (
      fieldName === "dependent_field" &&
      otherField.value === "specific_value" &&
      fieldValue === ""
    ) {
      return (
        field.dataset.tooltip ||
        "This field is required when the other field has a specific value."
      );
    }

    return null;
  }

  static isValidEmail(email) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  }

  static isValidPostalCode(postalCode) {
    const postalCodeRegex = /^\d{4}\s?[A-Za-z]{2}$/;
    return postalCodeRegex.test(postalCode);
  }

  static containsSubstring(value, substring) {
    return value.includes(substring);
  }

  static checkPasswordStrength(password) {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;

    return (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      numberRegex.test(password) &&
      specialCharRegex.test(password)
    );
  }

  static validateCheckboxRequired(checkbox, required = false) {
    if (required && !checkbox.checked) {
      return checkbox.dataset.tooltip || "This field is required.";
    }

    return null;
  }

  static validateCheckboxGroup(checkboxes, min = 0, max = checkboxes.length) {
    const checked = checkboxes.filter((checkbox) => checkbox.checked);

    if (checked.length < min) {
      return `You must select at least ${min} option(s).`;
    }

    if (checked.length > max) {
      return `You can only select up to ${max} option(s).`;
    }

    return null;
  }
}
